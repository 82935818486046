import uniqBy from "lodash/uniqBy";
import { useState, useEffect } from "react";
import { FaqsFragment } from "src/__generated__/client.codegen";
import {
	Topic,
	useTopicSelector,
} from "src/components/TopicSelector/TopicSelector";
import { useTranslatedString } from "src/i18n/i18n";
import { truthy } from "src/lib/utils";

const useFaqFilter = (faqs: Array<FaqsFragment>) => {
	const t = useTranslatedString();

	const [searchTerm, setSearchTerm] = useState("");
	const [filteredFaqs, setFilteredFaqs] = useState(faqs);
	const [filteredTopics, setFilteredTopics] = useState<Array<Topic>>([]);

	const { currentTopic, setCurrentTopic } = useTopicSelector(
		faqs
			.map(({ insuranceProductFamily }) => insuranceProductFamily)
			.filter(truthy)
			.map(({ name, slug, color: { hex } }) => ({
				name,
				slug,
				color: hex,
			})),
		t("faqSection.general"),
	);

	// Filter FAQs based on the search term
	useEffect(() => {
		const lowerCaseSearchTerm = searchTerm.toLowerCase();

		const filtered = faqs
			.map((faq) => ({
				...faq,
				questions: faq.questions.filter(
					({ question, answer }) =>
						question.toLowerCase().includes(lowerCaseSearchTerm) ||
						answer.toLowerCase().includes(lowerCaseSearchTerm),
				),
			}))
			// Keep FAQs with non-empty questions
			.filter((faq) => faq.questions.length > 0);

		setFilteredFaqs(filtered);
	}, [faqs, searchTerm]);

	// Update topics and current topic based on filtered FAQs
	useEffect(() => {
		if (!(filteredFaqs.length > 0)) {
			setFilteredTopics([]);
		}

		if (filteredFaqs.length > 0) {
			const topics = uniqBy(
				filteredFaqs.map(({ insuranceProductFamily }) => ({
					name:
						insuranceProductFamily?.name ?? t("faqSection.general"),
					slug:
						insuranceProductFamily?.slug ??
						t("blogpost.allTopics.slug"),
					color: insuranceProductFamily?.color.hex ?? "brand.dark",
				})),
				"slug",
			).sort((a, b) => a.name.localeCompare(b.name));

			setFilteredTopics(topics);
			setCurrentTopic(topics.at(0)?.slug ?? "all");
		}
	}, [filteredFaqs, setCurrentTopic, t]);

	// Filter FAQs based on current topic
	const filteredFaqsByFamily = filteredFaqs.filter(
		({ insuranceProductFamily }) =>
			insuranceProductFamily
				? insuranceProductFamily.slug === currentTopic
				: currentTopic === "all",
	);

	return {
		searchTerm,
		setSearchTerm,
		filteredFaqs,
		filteredTopics,
		currentTopic,
		setCurrentTopic,
		filteredFaqsByFamily,
	};
};

export default useFaqFilter;

// 🔬 TBD: Please evaluate
