import {
	chakra,
	Link as ChakraLink,
	LinkBox,
	LinkOverlay,
	useBreakpointValue,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { CMSButtonLabel } from "src/components/CMSButton/CMSButtonLabel";
import { DatoAsset } from "src/components/Dato/DatoAsset";
import { HeadingLevelBoundary, Hx } from "src/components/Heading/Heading";
import { NextLink } from "src/components/NextLink";
import { useTranslatedString } from "src/i18n/i18n";
import { BlogpostProps } from "src/lib/ssr/getStaticProps/getStaticProps.blog.service";
import { DateDisplay } from "../DateDisplay/DateDisplay";

export type BlogpostTeaserProps = {
	blogpost: BlogpostProps["blogposts"][number];
};

export const BlogpostTeaser: React.FC<BlogpostTeaserProps> = ({ blogpost }) => {
	const styles = useMultiStyleConfig("Blogpost", { breakpoint: "md" });
	const t = useTranslatedString();

	const isMobile = useBreakpointValue(
		{
			base: true,
			md: false,
		},
		{ fallback: "base" },
	);

	const {
		id,
		slug,
		title,
		date,
		productFamily: { name },
		teaserText,
		teaserImage,
	} = blogpost;

	return (
		<LinkBox as="article" key={id} sx={styles.previewWrapper}>
			<chakra.div __css={styles.previewImageWrapper}>
				<DatoAsset
					data={teaserImage}
					datoImageProps={{
						objectFit: "cover",
						layout: "fill",
					}}
				/>
			</chakra.div>
			<chakra.div __css={styles.previewTextWrapper}>
				{!isMobile && (
					<chakra.div __css={styles.previewMetadata}>
						<chakra.span flexGrow={1}>{name}</chakra.span>
						<DateDisplay date={date} />
					</chakra.div>
				)}
				<NextLink href={`${t("blog.urlSlug")}/${slug}`} passHref>
					<LinkOverlay>
						<HeadingLevelBoundary>
							<Hx sx={styles.previewTitle}>{title}</Hx>
						</HeadingLevelBoundary>
					</LinkOverlay>
				</NextLink>
				<chakra.div flexGrow={1}>{teaserText}</chakra.div>
				<NextLink href={`${t("blog.urlSlug")}/${slug}`} passHref>
					<ChakraLink id={id} color="brand.highlight">
						<CMSButtonLabel label={t("blogpost.readMore")} />
					</ChakraLink>
				</NextLink>
			</chakra.div>
		</LinkBox>
	);
};

// 🔬 e2e: story
