import {
	chakra,
	Container,
	Flex,
	Input,
	useMultiStyleConfig,
	Box,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import uniqBy from "lodash/uniqBy";
import React, { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
	NewsletterSectionFragment,
	useGetAllInsuranceProductsQuery,
} from "src/__generated__/client.codegen";
import { BackgroundShape } from "src/components/BackgroundShape/BackgroundShape";
import { FormErrorMessageI18n } from "src/components/FormErrorMessageI18n/FormErrorMessageI18n";
import { FormSubmitButton } from "src/components/FormSubmitButton";
import { Hx } from "src/components/Heading/Heading";
import { FormSelect } from "src/components/Inputs/FormSelect";
import { Loading } from "src/components/Loading/Loading";
import { filterInsuranceProductsForForm } from "src/components/PremiumCalcModal/utils";
import {
	isStructuredText,
	StructuredText,
} from "src/components/StructuredText/StructuredText";
import { FormControl, FormLabel } from "src/components/forms";
import { useTranslatedString } from "src/i18n/i18n";
import { Handshake, Submit } from "src/icons";
import { useGTMSubmitSignup } from "src/lib/gtm";
import { usePortalType, useRole, useSiteSettings } from "src/lib/hooks";
import { replacePipeWithShy } from "src/lib/replaceStringWithReactNode";
import { useNewsletterSignup } from "src/lib/useNewsletterSignup";
import { truthy } from "src/lib/utils";
import { z } from "src/lib/zod";
import { useLoginInfo } from "src/queries/emil/account";

type NewsletterProps = Omit<NewsletterSectionFragment, "__typename">;

export const NewsletterSection: React.FC<NewsletterProps> = ({
	topContent,
	bottomContent,
}) => {
	const t = useTranslatedString();
	const styles = useMultiStyleConfig("NewsletterSignup", {
		variant: "targetGroup",
	});
	const trackSuccessfulSignup = useGTMSubmitSignup();
	const { locale } = useSiteSettings();
	const { data, isLoading: isLoadingInsuranceProducts } =
		useGetAllInsuranceProductsQuery({
			locale,
		});
	const loginInfo = useLoginInfo();
	const rolePermission = useRole();
	const portal = usePortalType();

	const form = useForm<{ email: string; formUrl: string }>({
		resolver: zodResolver(
			z.object({
				email: z.string().email(),
				formUrl: z.string().min(1),
			}),
		),
		mode: "onTouched",
	});

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = form;

	const { mutate, isLoading, isSuccess } = useNewsletterSignup(() => {
		trackSuccessfulSignup();
		reset();
	});

	const insuranceProducts = useMemo(() => {
		const products = filterInsuranceProductsForForm(
			data?.allInsuranceProducts,
			loginInfo,
			rolePermission,
			portal,
		);

		return products;
	}, [data, loginInfo, portal, rolePermission]);

	const targetGroups = useMemo(() => {
		// we need to return the uniq slugs as one target group can belong to multiple products
		return uniqBy(
			insuranceProducts
				.map(({ targetGroup }) => targetGroup)
				.filter(truthy),
			"slug",
		);
	}, [insuranceProducts]);

	if (isLoadingInsuranceProducts) {
		<chakra.div __css={styles.wrapper} data-condition={isSuccess}>
			<BackgroundShape offset={55} />
			<Container maxW="container.md" sx={styles.inner}>
				<Loading />
			</Container>
		</chakra.div>;
	}

	if (!data) {
		return null;
	}

	// get all insurance products without tg and all target groups that have a newsletter URL
	const items = [
		...insuranceProducts.filter(({ targetGroup }) => !targetGroup),
		...targetGroups,
	].filter((item) => Boolean(item.sendinblueFormUrl));

	return (
		<chakra.div __css={styles.wrapper} data-condition={isSuccess}>
			<BackgroundShape offset={55} />
			<Container maxW="container.md" sx={styles.inner}>
				<chakra.div __css={styles.success}>
					<Hx sx={styles.title}>{t("newsletter.success.title")}</Hx>
					<Handshake w="auto" h="10rem" mb={8} />
					<Box __css={styles.intro}>
						{t("newsletter.success.text")}
					</Box>
				</chakra.div>
				<chakra.div __css={styles.formWrapper}>
					<Box>
						{isStructuredText(topContent) && (
							<StructuredText data={topContent} />
						)}
					</Box>
					<form
						noValidate
						onSubmit={handleSubmit(({ email, formUrl }) =>
							mutate({ email, formUrl }),
						)}
					>
						<Flex sx={styles.form}>
							<FormControl isInvalid={Boolean(errors.email)}>
								<FormLabel htmlFor="email" sx={styles.label}>
									{t("newsletter.email.label")}
								</FormLabel>

								<Input
									{...register("email")}
									data-testid="email"
									placeholder={t(
										"newsletter.email.placeholder",
									)}
									variant="filled"
									sx={styles.input}
								/>
								<FormErrorMessageI18n error={errors.email} />
							</FormControl>
							<FormProvider {...form}>
								<FormSelect
									name="formUrl"
									label={t("newsletter.group.label")}
									placeholder={t(
										"newsletter.group.placeholder",
									)}
									isInvalid={Boolean(errors.formUrl)}
									sx={styles.formSelect}
									variant="light"
								>
									{items.map((item) => (
										<option
											key={item.id}
											value={
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
												item.sendinblueFormUrl!
											}
										>
											{replacePipeWithShy(item.name)}
										</option>
									))}
								</FormSelect>
							</FormProvider>
							<FormSubmitButton
								aria-label={t("newsletter.send")}
								data-testid="subscribe"
								sx={styles.submit}
								isLoading={isLoading}
								isValid={!errors.email && !errors.formUrl}
								rightIcon={<Submit w={6} h={6} />}
								id="submit_newsletter_form"
							>
								{t("newsletter.send")}
							</FormSubmitButton>
						</Flex>
					</form>
					<Box sx={{ p: { fontSize: "xs" } }}>
						{isStructuredText(bottomContent) && (
							<StructuredText data={bottomContent} />
						)}
					</Box>
				</chakra.div>
			</Container>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
